import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Image from "./Image";

const StyledProcessItem = styled.div`
	display: inline-block;
	background-color: white;
	width: 290px;
	height: 280px;
	box-shadow: 0 3px 0 rgba(90, 122, 190, 0.12);
	border-radius: 40px;
	border: 1px solid rgba(90, 122, 190, 0.08);
	margin: 0 0 20px 0;
	padding: 30px;
	box-sizing: border-box;
	transition: box-shadow 0.3s;

	.icon {
		margin-bottom: 10px;
	}
	.icon img {
		margin: auto;
		height: 30px;
	}
	.headline {
		margin-bottom: 20px;
		text-transform: uppercase;
		letter-spacing: 1.67px;
		font-weight: 500;
		color: #1f2c3a;
		transition: color 0.3s;
	}
	.content {
		font-size: 14px;
		color: rgba(31, 44, 58, 0.5);
		transition: color 0.3s;
	}
	&:hover {
		.headline,
		.content {
			color: #2aad7c;
		}
		.icon img {
			filter: invert(46%) sepia(93%) saturate(355%) hue-rotate(106deg)
				brightness(103%) contrast(82%);
		}
		box-shadow: 0 3px 0 rgba(90, 122, 190, 0.12),
			0 10px 20px rgba(90, 122, 190, 0.2);
	}
	@media only screen and (max-width: 1200px) {
		margin: 0 0 20px 0;
	}
	@media only screen and (max-width: 460px) {
		width: 250px;
		height: 360px;
	}
`;

const ProcessItem = ({ icon, headline, content }) => {
	return (
		<StyledProcessItem className="home_process-item">
			<div className="icon">
				{icon && <Image data={icon} alt="synetech-icon" />}
			</div>
			<div className="headline">{headline}</div>
			<div className="content">{content}</div>
		</StyledProcessItem>
	);
};

ProcessItem.propTypes = {
	icon: PropTypes.shape({
		publicUrl: PropTypes.string
	}).isRequired,
	headline: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired
};

export default ProcessItem;
