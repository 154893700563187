import React from "react";
import { string, arrayOf, shape } from "prop-types";
import Image from "../Image";
import StyledHomeReferenceItems from "./style";

const HomeReferenceItems = ({ data, ...rest }) => (
	<StyledHomeReferenceItems {...rest}>
		<div className="ref-wrap">
			{data.map(item => {
				return (
					<Image
						durationFadeIn={200}
						key={item.alt}
						data={item.img}
						alt={item.alt}
					/>
				);
			})}
		</div>
	</StyledHomeReferenceItems>
);

HomeReferenceItems.propTypes = {
	data: arrayOf(
		shape({
			alt: string.isRequired
		})
	).isRequired
};

export default HomeReferenceItems;
