import styled from "styled-components";

const StyledHomeReferenceItems = styled.div`
	width: 100%;
	margin: auto;
	padding: 30px 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.ref-wrap {
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 800px;
		width: 100%;
	}
	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

export default StyledHomeReferenceItems;
