import React from "react";
import { string, node, number, shape } from "prop-types";
import StyledHomeBottomBg from "./style";

const HomeBottomBg = ({ children, fluid, ...rest }) => {
	if (
		typeof fluid.childImageSharp !== "undefined" &&
		fluid.childImageSharp.fluid
	) {
		return (
			<StyledHomeBottomBg
				fadeIn={false}
				fluid={fluid.childImageSharp.fluid}
				{...rest}
			>
				{children}
			</StyledHomeBottomBg>
		);
	}
	return null;
};

HomeBottomBg.propTypes = {
	children: node.isRequired,
	fluid: shape({
		aspectRatio: number,
		sizes: string,
		src: string,
		srcSet: string,
		srcSetWebp: string,
		srcWebp: string
	}).isRequired
};

export default HomeBottomBg;
