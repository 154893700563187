import React from "react";
import PropTypes, { string } from "prop-types";
import styled from "styled-components";
import Image from "../Image";

export const StyledHpCta = styled.a`
	width: 48.15%;
	background-color: #f5f5f5;
	border-radius: 40px;
	color: #2aad7c;
	transition: background-color 0.2s;
	display: flex;
	padding: 37px;
	position: relative;
	margin-bottom: 25px;
	text-decoration: none;
	align-items: center;

	&:hover {
		background-color: #eaeaea;
	}
	.hpCta__image {
		display: inline-block;
		height: 35px;
		position: absolute;
		left: 37px;
		top: 50%;
		transform: translateY(-50%);
	}
	.hpCta__label {
		font-weight: 500;
		line-height: 26px;
		padding-left: 78px;
	}

	@media only screen and (max-width: 800px) {
		width: 100%;
	}
`;

const HpCta = ({ label, image, link }) => {
	return (
		<StyledHpCta href={link}>
			<span className="hpCta__image">
				<Image data={image} alt={label} />
			</span>
			<span className="hpCta__label">{label}</span>
		</StyledHpCta>
	);
};

HpCta.propTypes = {
	image: PropTypes.object.isRequired,
	link: string.isRequired,
	label: string.isRequired
};

export default HpCta;
