import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Layout from "../components/Layout";
import CommonContent from "../components/CommonContent";
import Main from "../components/Main";
import ButtonLink from "../components/ButtonLink";
import ContentRow from "../components/ContentRow";
import ContactRow from "../components/ContactRow";
import ProcessSlider from "../components/ProcessSlider";
import BlogItem from "../components/BlogItem";
import SEO from "../components/seo";
import Image from "../components/Image";
import key from "../utils/string2key";
import HPCard from "../components/Card/HPCard";
import HpCta from "../components/Card/HpCta";
import HomeBottomBg from "../components/HomeBottomBg";
import "slick-carousel/slick/slick.css";
import HomeReferenceItems from "../components/HomeReferenceItems";

const HomeCardsSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 1215px;
	margin: auto;
	padding: 0 20px;

	@media only screen and (max-width: 800px) {
		padding: 0 20px;
	}
`;
const HomeSection = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1215px;
	margin: auto;
	padding-top: 160px;
	padding-bottom: 40px;
	padding-left: 20px;
	padding-right: 20px;
	.homeSection__left {
		width: 47%;
	}
	.homeSection__img {
		width: 100%;
		height: 100%;
	}
	.homeSection__right {
		width: 50%;
		align-items: start !important;
		h1 {
			color: #1f2c3a;
			font-size: 48px;
			line-height: 58px;
			padding-bottom: 35px;
		}
		p {
			opacity: 0.75;
			color: #1f2c3a;
			font-size: 18px;
			font-weight: 500;
			line-height: 30px;
			padding-bottom: 20px;
		}
		.button-link {
			width: 100%;
			margin-bottom: 30px;
		}
	}
	.buttonIcon {
		position: absolute;
		left: 16px;
		top: 50%;
		transform: translateY(-50%);
		max-height: 20px;
	}

	@media only screen and (max-width: 900px) {
		flex-direction: column;
		text-align: center;
		padding-top: 80px;
		.homeSection__left {
			width: 100%;
		}
		.homeSection__img {
			margin: auto;
			width: 80%;
		}
		.homeSection__right {
			width: 100%;
		}
	}
`;

const HomeAppSection = styled.div`
	width: 100%;
	@media only screen and (max-width: 1200px) {
		padding: 50px 30px;
	}
	@media only screen and (min-width: 2350px) {
		h2 {
			font-size: 44px;
			font-weight: 400;
		}

		.content {
			font-size: 26px;
			line-height: 34px;
		}
	}
`;

const Synevision = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1215px;
	margin: auto;
	padding-bottom: 40px;
	padding-left: 20px;
	padding-right: 20px;
	.homeSection__left {
		width: 47%;
		align-items: start !important;
		h1 {
			color: #1f2c3a;
			font-size: 48px;
			line-height: 58px;
			padding-bottom: 35px;
		}
		p {
			opacity: 0.75;
			color: #1f2c3a;
			font-size: 18px;
			font-weight: 500;
			line-height: 30px;
			padding-bottom: 20px;
		}
		.button-link {
			width: 100%;
		}
	}
	.homeSection__img {
		width: 100%;
		height: 100%;
	}
	.homeSection__right {
		width: 47%;
		padding-bottom: 30px;
	}
	.buttonIcon {
		position: absolute;
		left: 16px;
		top: 50%;
		transform: translateY(-50%);
		max-height: 20px;
	}

	.syneIcon {
		width: 210px;
		margin-bottom: 25px;
	}
	.syneIcon2 {
		width: 114px;
		margin: auto;
		display: none;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 900px) {
		flex-direction: column;
		text-align: center;
		padding-top: 80px;
		.syneIcon {
			display: none;
		}
		.syneIcon2 {
			display: block;
		}
		.homeSection__left {
			width: 100%;
			order: 2;
		}
		.homeSection__img {
			margin: auto;
			width: 80%;
			order: 1;
		}
		.homeSection__right {
			width: 100%;
		}
	}
`;

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 5000,
	arrows: false,
	pauseOnHover: false,
	dotsClass: "slick-dots",
	initialSlide: 1
};

const HpSlick = styled.div`
	.slick-slide {
		padding: 5px;
	}
	.slick-dots {
		list-style-type: none;
		display: flex !important;
		justify-content: center;
		li {
			padding: 10px;
			flex-grow: 1;
			max-width: 100px;
		}
		li > button {
			width: 100%;
			height: 10px;
			background-color: #f6f6f6;
			border-radius: 5px;
			transition: background-color 0.2s;
			font-size: 0px;
			border: none;
		}
		li.slick-active > button {
			background-color: #1f2c3a;
		}
	}
`;

const IndexPage = ({ data, pageContext }) => {
	const blogPosts = Array.from(
		new Set([...data.topPosts.edges, ...data.posts.edges])
	);
	return (
		<Layout pageContext={pageContext}>
			<Main className="page-container">
				<SEO
					title={pageContext.HOME_META_TITLE}
					description={pageContext.HOME_META_DESCRIPTION}
					lang={pageContext.lang}
					keywords={["vývoj aplikací"]}
				/>
				<HomeSection>
					<div className="homeSection__left">
						<Image
							durationFadeIn={200}
							className="homeSection__img"
							objectPosition="100% 0"
							objectFit="cover"
							data={data.main.image}
							alt={data.main.header}
						/>
					</div>
					<CommonContent
						headline={data.main.header}
						content={data.main.content}
						wrapperClass="homeSection__right"
						contentSize="big"
						buttons={data.main.buttons.map((button, i) => (
							<ButtonLink
								link={button.link}
								key={button.label}
								alt={button.label}
								label={button.label}
								classString={`link green-font hp-main ${
									button.image === null ? "" : "with-icon "
								}${i === 0 ? "link " : "link  green-bg"}`}
								icon={
									button.image === null ? (
										undefined
									) : (
										<Image
											className="buttonIcon"
											data={button.image}
											alt={button.label}
										/>
									)
								}
							/>
						))}
					/>
				</HomeSection>
				<HomeReferenceItems
					data={data.homeReferenceItems.images}
					style={{
						marginBottom: "105px",
						borderTop: "1px solid #dbdbdb",
						borderBottom: "1px solid #dbdbdb"
					}}
				/>
				<ContentRow rowContentClass="full-size no-padding">
					<h2
						style={{
							color: "#1F2C3A",
							fontSize: "36px",
							lineHeight: "44px",
							textAlign: "center",
							paddingBottom: "30px"
						}}
					>
						{data.homeCards.hpHeadline}
					</h2>
					<HomeCardsSection>
						{data.homeCards.content.map(card => (
							<HPCard
								key={card.hp.headline}
								label={card.hp.label}
								image={card.hp.image}
								headline={card.hp.headline}
								content={card.hp.content}
								buttonLabel={card.hp.buttonLabel}
								link={card.hp.link}
								technologies={card.hp.technologies}
							/>
						))}
					</HomeCardsSection>
				</ContentRow>
				<ContentRow
					rowContentClass="full-size no-padding"
					style={{ marginTop: "40px" }}
				>
					<Synevision>
						<div className="homeSection__left">
							<Image
								durationFadeIn={200}
								className="syneIcon"
								data={data.synevision.image}
								alt={data.synevision.header}
							/>
							<CommonContent
								headline={data.synevision.header}
								content={data.synevision.content}
								contentSize="big"
								buttons={[
									<ButtonLink
										link={data.synevision.button.link}
										label={data.synevision.button.label}
										classString="link green-font hp-main"
									/>
								]}
							/>
						</div>
						<div className="homeSection__right">
							<Image
								durationFadeIn={200}
								className="syneIcon2"
								data={data.synevision.image2}
								alt={data.synevision.header}
								objectPosition="50% 50%"
								objectFit="contain"
							/>
							<HpSlick>
								<Slider {...settings} className="hp-slick">
									{data.synevision.slider.map(slide => (
										<Image durationFadeIn={200} data={slide} alt="" />
									))}
								</Slider>
							</HpSlick>
						</div>
					</Synevision>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size no-padding">
					<ProcessSlider
						headline={data.process.headline}
						items={data.process.content}
						background={data.process.background}
					/>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size no-padding">
					<HomeCardsSection style={{ paddingBottom: "75px" }}>
						{data.usefulLinks.content.map(cta => (
							<HpCta
								key={cta.label}
								label={cta.label}
								image={cta.image}
								link={cta.link}
							/>
						))}
					</HomeCardsSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size no-padding">
					<HomeBottomBg
						className="home_bottom-bg"
						id="blog"
						fluid={data.home_card_bg}
					>
						<div className="home_blog-section" id="blog">
							<h2
								style={{
									color: "#1F2C3A",
									fontSize: "36px",
									lineHeight: "44px",
									textAlign: "center",
									paddingBottom: "16px"
								}}
							>
								{pageContext.HOME_BLOG_HEADLINE}
							</h2>
							<div className="blog-subheadline">
								<p>{pageContext.HOME_BLOG_SUBHEADLINE_1}</p>
								<p>{pageContext.HOME_BLOG_SUBHEADLINE_2}</p>
							</div>
							<div className="home_blog-items">
								{blogPosts.slice(0, 3).map(item => (
									<BlogItem
										buttonLabel={pageContext.HOME_BLOG_BUTTON}
										link={`/${pageContext.lang}/blog/${item.node.frontmatter.url}`}
										itemClass="home_blog-item-1 blog-item"
										headline={item.node.frontmatter.title}
										content={item.node.frontmatter.description}
										key={key(item.node.frontmatter.title)}
									/>
								))}
							</div>
						</div>
					</HomeBottomBg>
				</ContentRow>
				<ContactRow pageContext={pageContext} />
			</Main>
		</Layout>
	);
};

IndexPage.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};

export default IndexPage;

export const query = graphql`
	query IndexQuery($lang: String!) {
		home_card_bg: file(relativePath: { eq: "home-card-bg-hd.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		homeReferenceItems: homeReferenceItemsJson {
			images {
				img {
					publicURL
					extension
					childImageSharp {
						fixed(height: 250) {
							...GatsbyImageSharpFixed_withWebp_noBase64
						}
					}
				}
				alt
			}
		}
		topPosts: allMdx(
			filter: {
				fields: { source: { eq: "posts" } }
				frontmatter: { lang: { eq: $lang }, isTop: { eq: true } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 3
		) {
			edges {
				node {
					frontmatter {
						title
						description
						category
						url
						date
						author
						lang
						cover {
							childImageSharp {
								fluid(maxWidth: 800, maxHeight: 360) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		}
		posts: allMdx(
			filter: {
				fields: { source: { eq: "posts" } }
				frontmatter: { lang: { eq: $lang }, isTop: { eq: false } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 3
		) {
			edges {
				node {
					frontmatter {
						title
						description
						category
						url
						date
						author
						lang
						cover {
							childImageSharp {
								fluid(maxWidth: 800, maxHeight: 360) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		}
		categories: allMdx(
			filter: {
				fields: { source: { eq: "categories" } }
				frontmatter: { lang: { eq: $lang } }
			}
		) {
			edges {
				node {
					frontmatter {
						categoryName
						title
						url
					}
				}
			}
		}
		homeCards: projectsJson(title: { eq: $lang }) {
			hpHeadline
			content {
				hp {
					visible
					label
					image {
						childImageSharp {
							fixed(height: 250) {
								...GatsbyImageSharpFixed_withWebp_noBase64
							}
						}
					}
					headline
					content
					buttonLabel
					link
					technologies
				}
			}
		}
		usefulLinks: hpCtaJson(title: { eq: $lang }) {
			content {
				visible
				label
				link
				image {
					publicURL
				}
			}
		}
		main: hpMainJson(lang: { eq: $lang }) {
			header
			image {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			content
			buttons {
				link
				label
				image {
					publicURL
				}
			}
		}
		synevision: hpSynevisionJson(lang: { eq: $lang }) {
			header
			content
			image {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			image2 {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			slider {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			button {
				link
				label
			}
		}
		process: processSliderJson(lang: { eq: $lang }) {
			headline
			content {
				HEADLINE
				CONTENT
				IMAGE {
					publicURL
				}
			}
			background {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	}
`;
