import React from "react";
import { string } from "prop-types";
import styled from "styled-components";
import ButtonLink from "./ButtonLink";
import CommonContent from "./CommonContent";

const StyledBlogItem = styled.div`
	line-height: 20px;
	.blog-subheadline {
		padding: 20px 0;
	}
	.blog-item {
		padding: 20px;
		box-sizing: border-box;
		text-align: left;
		height: 100%;
	}
	.headline {
		margin-bottom: 12px;
	}
	.headline h2 {
		font-size: 18px;
		font-weight: 500;
		line-height: 22px;
		color: #161616;
	}
	.content {
		font-size: 14px;
		color: #525252;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		min-height: 180px;
	}

	@media only screen and (max-width: 1200px) {
		h2 {
			font-size: 2em;
		}
		.blog-item {
			text-align: left;
		}
	}
`;

const BlogItem = ({ link, headline, content, itemClass, buttonLabel }) => (
	<StyledBlogItem>
		<CommonContent
			headline={headline}
			content={content}
			wrapperClass={itemClass}
			buttons={[
				<ButtonLink
					classString="link green-font small"
					link={link}
					label={buttonLabel}
				/>
			]}
		/>
	</StyledBlogItem>
);

export default BlogItem;

BlogItem.propTypes = {
	link: string.isRequired,
	headline: string.isRequired,
	content: string.isRequired,
	itemClass: string.isRequired,
	buttonLabel: string.isRequired
};
