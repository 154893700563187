import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import React from "react";

const StyledHomeBottomBg = styled(props => <BackgroundImage {...props} />)`
	width: 100%;
	padding: 50px 0;
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: auto;

	.home_blog-section {
		text-align: center;
		margin: auto;
		width: 1180px;
	}
	.home_blog-items {
		padding: 70px 0 70px 0;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 46px;
	}
	@media only screen and (max-width: 1200px) {
		text-align: center;
		width: 100%;
		padding: 30px;
		box-sizing: border-box;

		.home_blog-section {
			width: 100%;
		}
	}

	@media only screen and (max-width: 1200px) {
		.home_blog-items {
			display: grid;
			grid-template-columns: auto;
			grid-template-rows: auto auto auto;
			grid-column-gap: 0;
			grid-row-gap: 30px;
		}
		.blog-item {
			margin: 0 !important;
			width: 100%;
		}
	}
`;

export default StyledHomeBottomBg;
