import React from "react";
import PropTypes, { string, array } from "prop-types";
import GatsbyImg from "gatsby-image/withIEPolyfill";
import { Link } from "gatsby";
import { StyledHPCard } from "./style";
import Img from "../Image";

const HPCard = ({
	label,
	image,
	headline,
	content,
	buttonLabel,
	link,
	technologies
}) => (
	<StyledHPCard>
		<div className="hpCard__wrapper">
			<div className="hpCard__header">
				<strong className="hpCard__label">{label}</strong>
				{technologies && (
					<div className="hpCard__header__technology">
						{technologies.map(t => (
							<span>{t}</span>
						))}
					</div>
				)}
			</div>
			<div className="hpCard__image">
				<Img
					// fixed={image.childImageSharp.fixed}
					data={image}
					alt={headline}
					className="hpCard__gatsbyImage"
					objectFit="scale-down"
					objectPosition="50% 100%"
				/>
			</div>
			<h3 className="hpCard__headline">{headline}</h3>
			<span className="hpCard__content">{content}</span>
		</div>
		{link !== "" && (
			<Link to={link} className="hpCard__button">
				{buttonLabel}
			</Link>
		)}
	</StyledHPCard>
);

HPCard.propTypes = {
	image: PropTypes.object.isRequired,
	headline: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	link: string,
	buttonLabel: string.isRequired,
	label: string.isRequired,
	technologies: array.isRequired
};

HPCard.defaultProps = {
	link: ""
};

export default HPCard;
