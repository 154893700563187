import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import PropTypes from "prop-types";
import ProcessItem from "./ProcessItem";
import key from "../utils/string2key";
import BGImg from "./BGImg";

const StyledHomeProcessSection = styled.div`
	text-align: center;
	width: 100%;
	max-width: 1440px;
	margin: auto;
	padding: 70px 0 170px 0;
	h2 {
		padding-bottom: 55px;
		color: #1f2c3a;
		font-size: 36px;
		line-height: 44px;
		text-align: center;
	}

	@media only screen and (max-width: 1200px) {
		text-align: center;
		width: 100%;
		margin: 0;
		padding: 70px 20px;
		box-sizing: border-box;
	}
	@media only screen and (max-width: 459px) {
		padding-left: 0;
		padding-right: 0;
	}
	@media only screen and (min-width: 701px) {
		.slick-list {
			padding-left: 0 !important;
		}
	}
`;

const StyledBackground = styled(props => <BGImg {...props} />)`
	&:before,
	&:after {
		@media only screen and (max-width: 800px) {
			display: none !important;
		}
	}
	background-size: contain;
`;

function ProcessSlider({ headline, items, background }) {
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		initialSlide: 2,
		centerPadding: "3.5%",
		responsive: [
			{
				breakpoint: 1250,
				settings: {
					slidesToShow: 3,
					centerPadding: "4%"
				}
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 2,
					centerPadding: "6%"
				}
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1,
					centerPadding: "15%"
				}
			},
			{
				breakpoint: 380,
				settings: {
					slidesToShow: 1,
					centerPadding: "10%"
				}
			}
		]
	};

	const isClient = typeof window === "object";
	const [windowWidth, setWindowWidth] = useState(
		isClient ? window.innerWidth : 0
	);

	useEffect(() => {
		function handleResize() {
			setWindowWidth(isClient ? window.innerWidth : 0);
		}
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount and unmount
	return (
		<StyledHomeProcessSection>
			<h2>{headline}</h2>
			<StyledBackground hookData={background}>
				<Slider {...settings}>
					{items.map(item => (
						<ProcessItem
							headline={item.HEADLINE}
							content={item.CONTENT}
							icon={item.IMAGE}
							key={key(item.HEADLINE)}
						/>
					))}
					{windowWidth > 1200 && <div />}
					{windowWidth > 700 && <div />}
				</Slider>
			</StyledBackground>
		</StyledHomeProcessSection>
	);
}

ProcessSlider.propTypes = {
	headline: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			HEADLINE: PropTypes.string.isRequired,
			CONTENT: PropTypes.string.isRequired,
			IMAGE: PropTypes.shape({
				publicURL: PropTypes.string.isRequired
			}).isRequired
		})
	).isRequired
};

export default ProcessSlider;
